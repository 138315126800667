<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item
          v-if="insideDevicesOrDevice"
          :to="{ name: 'devices'}"
          tag="router-link"
        >
          Managed devices
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-else
          :to="{ name: 'executions'}"
          tag="router-link"
        >
          Execution log
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-if="'executiondevices' === currentRouteName"
          :to="{ name: 'execute'}"
          tag="router-link"
        >
          Execute
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-if="insideADevice"
          :to="{ name: 'device', params: { deviceId: currentSelectedDevice.id }}"
          tag="router-link"
        >
          {{ currentSelectedDevice.name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-if="insideADevice"
          :to="{ name: 'executedevice', params: { deviceId: currentSelectedDevice.id }}"
          tag="router-link"
        >
          Execute
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ insideDevicesOrDevice ? 'Result' : 'Details' }}
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
      internal-card="card material-card material-card-content"
    >
      <div
        slot="external-body"
      >
        <executions-group-card
          v-if="executionGroups && executionGroups.length > 0"
          :execution-group="executionGroups[0]"
          :displaying-execution-details="true"
        />
      </div>
      <div slot="external-footer">
        <div
          v-show="executionGroups && executionGroups.length < 1"
          class="card container material-card execution-group-card"
        >
          <div class="content has-text-grey has-text-centered empty-content-message">
            <b-icon
              icon="lightning-bolt"
              size="is-large"
            />
            <p class="subtitle">
              Execution not found
            </p>
            <router-link
              :to="`/${$route.params.companyId}/executions`"
              class="button is-primary"
              style="color: #fff !important;"
            >
              View all executions
            </router-link>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import card from '@/components/cross/Card.vue';
import executionsGroupCard from '@/components/executions/ExecutionsGroupCard.vue';
import LoadingMixin from '@/mixins/loading';
import ErrorMixin from '@/mixins/error';

export default {
  name: 'ExecutionDetails',
  metaInfo: {
    title: 'Execution Result',
  },
  components: {
    card,
    'executions-group-card': executionsGroupCard,
  },
  mixins: [LoadingMixin, ErrorMixin, makeFindMixin({ service: 'execution-groups', watch: true })],
  data() {
    return {
      currentSelectedDevice: null,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('devices', ['getSelectedDevices']),
    currentRouteName() {
      return this.$route.name;
    },
    executionGroupsParams() {
      return {
        query: {
          companyId: this.currentCompany.id,
          id: this.$route.params.executionGroupId,
          $limit: 1,
        },
      };
    },
    insideDevicesOrDevice() {
      return ['executiondevices', 'executiondevice'].includes(this.currentRouteName);
    },
    insideADevice() {
      return this.currentRouteName === 'executiondevice' && this.currentSelectedDevice;
    },
  },
  watch: {
    executionGroups() {
      if (this.executionGroups.length > 0) {
        this.closeLoading();
      }
    },
  },
  created() {
    this.openLoading();
    if (this.executionGroups.length > 0) {
      this.closeLoading();
    }
    this.getCurrentDevice();
  },
  methods: {
    ...mapActions('devices', { getDevice: 'get' }),
    async getCurrentDevice() {
      const { selectedDevices } = this.getSelectedDevices(this.currentCompany.id);
      if (selectedDevices.length > 1) {
        return;
      }
      this.currentSelectedDevice = await this.getDevice([selectedDevices[0], {
        query: {
          companyId: this.currentCompany.id,
        },
      }]);
    },
  },
};
</script>

<style>
  .execution-group-card {
    margin-top: 12px !important;
  }
  .empty-content-message {
    padding: 3%
  }
  .hideOfflineDevicesToggle {
    margin-left: auto;
    font-weight: normal;
  }
  .pagination-container{
    margin-top: 15px;
  }
  .loading-overlay.is-full-page {
    z-index: 9 !important;
  }
  .styleForTitle {
    margin-bottom: 24px
  }
</style>
